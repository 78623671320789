import React from 'react';
import {
  SimpleGrid,
  VStack,
  HStack,
  Text,
  Heading,
  Skeleton,
  Spinner,
  Box,
  Tag,
  chakra,
  SkeletonCircle
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import salesforce from '../assets/salesforce.svg';
import drive from '../assets/drive.svg';
import { Customer } from '../assets/types';

interface DomainData {
  both_service_use?: string;
}

const CustomerDetailsGrid: React.FC<{ customerData: Customer | null, domainData: DomainData | null }> = ({ customerData, domainData }) => (
  <SimpleGrid columns={5} spacing={8}>
    <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
      <Text as="h2" fontSize={14} color="grey">Service type</Text>
      {customerData ?
        <Heading as="h2" fontSize={20} size="lg" >{customerData.service_type}</Heading> : <Skeleton width={120} height={6} />
      }
      {domainData ? domainData.both_service_use ? <Text>{domainData.both_service_use}</Text> : <></> : <VStack><Text>checking if both service use</Text><Spinner size="xs" /></VStack>}
    </VStack>

    <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
      <Text as="h2" fontSize={14} color="grey">Connection Date</Text>
      {customerData ?
        <Heading as="h2" fontSize={20} size="lg" >{customerData.connection_date}</Heading> : <Skeleton width={120} height={6} />
      }
    </VStack>
    <HStack shadow="md" bg="white" p={8} pb={10} spacing={4} justify="center" align="start">
      <VStack>
        <Text as="h2" fontSize={14} color="grey">Status</Text>
        {customerData ?
          <Heading as="h2" fontSize={20} size="lg" >{customerData.env}</Heading> : <Skeleton width={50} height={6} />
        }
      </VStack>
      <VStack>
        <Text as="h2" fontSize={14} color="grey">Type</Text>
        {customerData ?
          <Heading as="h2" fontSize={20} size="lg" >{customerData.env_type}</Heading> : <Skeleton width={50} height={6} />
        }
      </VStack>
    </HStack>
    <VStack shadow="md" bg="white" p={8} pb={4} spacing={0}>
      <HStack><Text as="h2" fontSize={14} color="grey">Links</Text> <ExternalLinkIcon mx='2px' /></HStack>
      <HStack alignItems="center" >
        {customerData ? customerData.id ? <a href={`https://hennge.my.salesforce.com/${customerData.id}`} target="_blank" rel="noreferrer" >   <chakra.img boxSize={50} src={salesforce} /></a> : <Text>-</Text> : <SkeletonCircle size="10" />}
        <Box width={4} />
        {customerData ? customerData.gdrive_url ? <a href={customerData.gdrive_url} target="_blank" rel="noreferrer" >    <chakra.img boxSize={50} src={drive} /></a> : <Text>-</Text> : <SkeletonCircle size="10" />}
      </HStack>
    </VStack>
    <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
      <Text as="h2" fontSize={14} color="grey">Support type</Text>
      {customerData ? customerData.support_type ? <Tag variant='solid' colorScheme='blue' >HENNGE One Support</Tag> : <Text>-</Text> : <Skeleton width={120} height={6} />}
    </VStack>
  </SimpleGrid>
);

export default CustomerDetailsGrid;
