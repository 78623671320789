import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Center,
    HStack,
    Heading,
    Skeleton,
    Spacer,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { CopyButtonWithLabel } from './CopyButtonWithLabel';
import { ServiceTypeImage } from './ServiceTypeImage';
import { TextModal } from './TextModal';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Domain, DomainInfo, Services } from '../assets/types';
import { CopyButton } from './CopyButton';

interface DomainAccordionProps {
    domainData: DomainInfo | null;
    customerDomains: string[] | null;
    shobossoData: Services | null;
    fzeroData: Services | null;
    nobitaV1Data: Services | null;
    kumamushiV1Data: Services | null;
    kumamushiV2Data: Services | null;
    customerDomainsIsLoading: string;
    shobossoDataIsLoading: string;
    fzeroDataIsLoading: string;
    nobitaV1DataIsLoading: string;
    kumamushiV1DataIsLoading: string;
    kumamushiV2DataIsLoading: string;
}

const DomainAccordion: React.FC<DomainAccordionProps> = ({
    domainData,
    customerDomains,
    shobossoData,
    fzeroData,
    nobitaV1Data,
    kumamushiV1Data,
    kumamushiV2Data,
    customerDomainsIsLoading,
    shobossoDataIsLoading,
    fzeroDataIsLoading,
    nobitaV1DataIsLoading,
    kumamushiV1DataIsLoading,
    kumamushiV2DataIsLoading
}) => {
    const [comparisonData, setComparisonData] = useState<string[][] | null>(null);

    useEffect(() => {
        if ([customerDomainsIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, kumamushiV1DataIsLoading, kumamushiV2DataIsLoading].some(status => status === "loading")) {
            return;
        }
        setComparisonData(comparisonDataResult(customerDomains, shobossoData, fzeroData, nobitaV1Data, kumamushiV1Data, kumamushiV2Data));
    }, [customerDomains, shobossoData, fzeroData, nobitaV1Data, kumamushiV1Data, kumamushiV2Data, customerDomainsIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, kumamushiV1DataIsLoading, kumamushiV2DataIsLoading]);

    function comparisonDataResult(customerDomains: string[] | null, shobossoData: Services | null, fzeroData: Services | null, nobitaV1Data: Services | null, kumamushiV1Data: Services | null, kumamushiV2Data: Services | null) {
        const all_domains = Array.from(new Set([
            ...(customerDomains ?? []),
            ...(shobossoData?.allowed_google_domains ?? []),
            ...(fzeroData?.fzero_allowed_domains ?? []),
            ...(nobitaV1Data?.allowed_envelope_domains ?? []),
            ...(nobitaV1Data?.allowed_originator_orgs ?? []),
            ...(kumamushiV1Data?.kumamushi_v1_domains ?? []),
            ...(kumamushiV2Data?.kumamushi_v2_domains ?? []),
        ]));

        const result: string[][] = [
            [
                "Customer Domain",
                "Allowed Google Domain",
                "DLP Allowed Domain",
                "HEA Allowed Envelop Domain",
                "HEA Allowed Originator Org",
                "Kumamushi v1 Tenant",
                "Kumamushi v2 Domain",
            ]
        ];

        all_domains.sort().forEach(domain => {
            result.push([
                domain,
                customerDomains?.includes(domain) ? "〇" : "-",
                shobossoData?.allowed_google_domains?.includes(domain) ? "〇" : "-",
                fzeroData?.fzero_allowed_domains?.includes(domain) ? "〇" : "-",
                nobitaV1Data?.allowed_envelope_domains?.includes(domain) ? "〇" : "-",
                nobitaV1Data?.allowed_originator_orgs?.includes(domain) ? "〇" : "-",
                kumamushiV1Data?.kumamushi_v1_domains_ox?.[domain] ?? "-",
                kumamushiV2Data?.kumamushi_v2_domains_ox?.[domain] ?? "-",
            ]);
        });

        return result;
    }

    return (
        <Accordion id="domains" defaultIndex={[0]} allowMultiple>
            <AccordionItem shadow="md" bg="white">
                <h2>
                    <Stack direction="row">
                        <HStack>
                            <Heading as="h2" size="md" p={4}>
                                Domains
                            </Heading>
                            {domainData && <CopyButtonWithLabel label="Copy All Domains" text={domainData.all_domains_str} m={0} />}
                            {domainData && <CopyButtonWithLabel label="Copy Valid Domains" text={domainData.valid_domains_str} m={0} />}
                        </HStack>
                        <Spacer />
                        <AccordionButton width="100" _focus={{ boxShadow: "none" }} >
                            <AccordionIcon />
                        </AccordionButton>
                    </Stack>
                </h2>
                <AccordionPanel pb={4}>
                    <Stack>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead >
                                    <Tr>
                                        <Th>Domain</Th>
                                        <Th>Main</Th>
                                        <Th>Status</Th>
                                        <Th>Document Only</Th>
                                        <Th>MX</Th>
                                        <Th>SPF</Th>
                                        <Th>SSO Service</Th>
                                        <Th>Remark</Th>
                                    </Tr>
                                </Thead>
                                {
                                    domainData ? <Tbody>
                                        {Object.values(domainData.domains).map((value: Domain, index: number) => <Tr key={index}>
                                            <Td><HStack><Text>{value.domain}</Text><CopyButton text={value.domain} /></HStack></Td>
                                            <Td><Center>{value.main}</Center></Td>
                                            <Td><Center>{value.status}</Center></Td>
                                            <Td><Center>{value.document_only}</Center></Td>
                                            <Td><Center>{value.mx}</Center></Td>
                                            <Td><Center>{value.spf}</Center></Td>
                                            <Td><Center><ServiceTypeImage service_type={value.service_type} /></Center></Td>
                                            <Td><Center><TextModal text={value.remark} show_length={5} title="Remark" /></Center></Td>
                                        </Tr>
                                        )}
                                    </Tbody> :
                                        <Tbody>
                                            <Tr>
                                                <Td> <Skeleton width={200} height={4} /></Td>
                                                <Td><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                                <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                                            </Tr>
                                        </Tbody>}
                            </Table>
                        </TableContainer>
                        <Spacer />
                        {domainData ?
                            <Stack>
                                <Accordion allowToggle>
                                    <AccordionItem >
                                        <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                                            <HStack>
                                                <AccordionIcon />
                                                <Text>Oukasho Customer Table</Text>
                                                <Spacer />
                                            </HStack>
                                        </AccordionButton>
                                        <AccordionPanel pb={6}>
                                            <SyntaxHighlighter language="javascript" >
                                                {domainData.oukasho_customer_table}
                                            </SyntaxHighlighter>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Accordion allowToggle>
                                    <AccordionItem >
                                        <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                                            <HStack>
                                                <AccordionIcon />
                                                <Text>Mapping of Services and Domains</Text>
                                                <Spacer />
                                            </HStack>
                                        </AccordionButton>
                                        <AccordionPanel pb={6}>
                                            <SyntaxHighlighter language="javascript" >
                                                {domainData.service_domains}
                                            </SyntaxHighlighter>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Stack>
                            : <Stack>
                                <Skeleton width={100} height={4} />
                                <Skeleton width={100} height={4} />
                            </Stack>
                        }

                        {comparisonData ?
                            <Accordion allowToggle>
                                <AccordionItem >
                                    <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                                        <HStack>
                                            <AccordionIcon />
                                            <Text>Compare Domains</Text>
                                            <Spacer />
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel pb={6}>
                                        <TableContainer>
                                            <Table variant='simple'>
                                                <Tbody>
                                                    <Tr><Td></Td>{Array.from(comparisonData[0]).map((td, number) => <Td backgroundColor="teal.500" color="white" key={number}>{td}</Td>)}</Tr>
                                                    {Array.from(comparisonData.slice(1)).map((row, trNumber) => <Tr key={trNumber}>
                                                        <Td backgroundColor="grey" color="white">{row[0]}</Td>
                                                        {Array.from(row.slice(1)).map((td, tdNumber) => <Td key={tdNumber}><Center>{td}</Center></Td>)}
                                                    </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            : <Skeleton width={100} height={4} />}
                    </Stack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default DomainAccordion;
