import React, { useState, useEffect } from 'react';
import {
    Stack,
    SimpleGrid,
    VStack,
    Skeleton,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    Text,
    Spinner,
    useDisclosure
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { Graph } from '../assets/types';
import { getApexChartsOptions } from '../assets/utils';
import { API_ROOT } from '../assets/const';

interface ChartSectionProps {
    customer_id: string;
}

const ChartSection: React.FC<ChartSectionProps> = ({ customer_id }) => {
    const [loadingCharts, setLoadingCharts] = useState<boolean>(true);
    const [karteLinks, setKarteLinks] = useState<object | null>(null);
    const [hacGraphData, setHacGraphData] = useState<Graph | null>(null);
    const [fzeroFilterData, setFzeroFilterData] = useState<Graph | null>(null);
    const [nobitaAmountData, setNobitaAmountData] = useState<Graph | null>(null);
    const [kumamushiV1GraphData, setKumamushiV1GraphData] = useState<Graph | null>(null);
    const [kumamushiV2GraphData, setKumamushiV2GraphData] = useState<Graph | null>(null);
    const { isOpen: isOpenKarte, onOpen: onOpenKarte, onClose: onCloseKarte } = useDisclosure();

    useEffect(() => {
        axios.get(`${API_ROOT}/charts/${customer_id}`)
            .then((response) => {
                setLoadingCharts(false);
                setKarteLinks(response.data.karte_links);
                setHacGraphData(response.data.hac ? {
                    options: getApexChartsOptions(
                        "shobosso",
                        "HAC usage",
                        ['#00E395', '#ff4560'],
                        response.data.hac.categories
                    ),
                    series: [{
                        name: 'success',
                        type: 'column',
                        data: response.data.hac.success_count
                    },
                    {
                        name: 'fail',
                        type: 'column',
                        data: response.data.hac.fail_count
                    }]
                } : null);
                setNobitaAmountData(response.data.nobita ? {
                    options: getApexChartsOptions(
                        "nobita-amount",
                        "Archive amount",
                        ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                        response.data.nobita.categories
                    ),
                    series: [
                        {
                            name: 'bsmtpd',
                            data: response.data.nobita.bsmtpd
                        },
                        {
                            name: 'jsmtpd',
                            data: response.data.nobita.jsmtpd
                        },
                        {
                            name: 'rsmtpd_in',
                            data: response.data.nobita.rsmtpd_in
                        },
                        {
                            name: 'rsmtpd_out',
                            data: response.data.nobita.rsmtpd_out
                        },
                    ]
                } : null);
                setFzeroFilterData(response.data.fzero ? {
                    options: getApexChartsOptions(
                        "fzero",
                        "DLP usage",
                        ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                        response.data.fzero.categories
                    ),
                    series: [
                        {
                            name: 'Send',
                            data: response.data.fzero.send
                        },
                        {
                            name: 'Suspend',
                            data: response.data.fzero.suspend
                        },
                        {
                            name: 'Request Approval',
                            data: response.data.fzero.request_approval
                        },
                        {
                            name: 'Delete',
                            data: response.data.fzero.delete
                        },
                    ]
                } : null);
                setKumamushiV1GraphData(response.data.kumamushi_v1 ? {
                    options: getApexChartsOptions(
                        "kumamushi_v1_graph",
                        "HST v1 usage",
                        ["#3399FF", "#33FF99"],
                        response.data.kumamushi_v1.categories
                    ),
                    series: [
                        {
                            name: 'sbundle count',
                            data: response.data.kumamushi_v1.sbundle_count
                        },
                        {
                            name: 'rbundle count',
                            data: response.data.kumamushi_v1.rbundle_count
                        },
                    ]
                } : null);
                setKumamushiV2GraphData(response.data.kumamushi_v2 ? {
                    options: getApexChartsOptions(
                        "kumamushi_v2_graph",
                        "HST v2 usage",
                        ["#3399FF", "#33FF99", "#FEB019"],
                        response.data.kumamushi_v2.categories
                    ),
                    series: [
                        {
                            name: 'HSD / Send',
                            data: response.data.kumamushi_v2.secure_download_send
                        },
                        {
                            name: 'HST / Send',
                            data: response.data.kumamushi_v2.secure_transfer_send
                        },
                        {
                            name: 'HST / Receive',
                            data: response.data.kumamushi_v2.secure_transfer_receive
                        },
                    ]
                } : null);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setLoadingCharts(false);
                    setKarteLinks({});
                }
                console.log(error);
            });
    }, [customer_id]);

    return (
        <Stack shadow="md" bg="white" p={4}>
            <SimpleGrid columns={3}>
                {[...Array(3)].map((_, index) => (
                    <VStack key={index}>
                        <Skeleton isLoaded={!loadingCharts} width={150} height={loadingCharts ? 4 : 0} />
                        <Skeleton isLoaded={!loadingCharts} width={300} height={loadingCharts ? 220 : 0} />
                    </VStack>
                ))}
                {
                    hacGraphData && <Chart options={hacGraphData.options} series={hacGraphData.series} type="bar" width={330} height={220} />
                }
                {
                    fzeroFilterData && <Chart options={fzeroFilterData.options} series={fzeroFilterData.series} type="bar" width={330} height={220} />
                }
                {
                    nobitaAmountData && <Chart options={nobitaAmountData.options} series={nobitaAmountData.series} type="bar" width={330} height={220} />
                }
                {
                    kumamushiV1GraphData && <Chart options={kumamushiV1GraphData.options} series={kumamushiV1GraphData.series} type="bar" width={330} height={220} />
                }
                {
                    kumamushiV2GraphData && <Chart options={kumamushiV2GraphData.options} series={kumamushiV2GraphData.series} type="bar" width={330} height={220} />
                }
            </SimpleGrid>
            {
                karteLinks ?
                    Object.keys(karteLinks).length !== 0 ?
                        <Button width={300} colorScheme="green" variant="outline" size="xs" _focus={{ boxShadow: "none" }} onClick={onOpenKarte}>
                            See more details
                        </Button> :
                        <Text>No DataScope Karte links</Text> :
                    <Skeleton width={300} height={4} />
            }
            <Modal onClose={onCloseKarte} isOpen={isOpenKarte} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>See more details in DataScope Karte</ModalHeader>
                    <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                    <ModalBody>
                        {karteLinks ?
                            <Stack>
                                {
                                    Object.entries(karteLinks).map(
                                        (domain_info_item, index) => (
                                            <Link key={index} href={domain_info_item[1]} isExternal>
                                                <HStack>
                                                    <Text color="green">{domain_info_item[0]}</Text>
                                                    <ExternalLinkIcon mx="2px" />
                                                </HStack>
                                            </Link>
                                        )
                                    )
                                }
                            </Stack> :
                            <Spinner />
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseKarte} _focus={{ boxShadow: 'none' }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    );
};

export default ChartSection;
