import React, { useEffect, useState } from 'react';
import { Box, Text, Progress } from '@chakra-ui/react';

interface RemarkStatusProps {
    customerDataIsLoading: string;
    shobossoDataIsLoading: string;
    fzeroDataIsLoading: string;
    nobitaV1DataIsLoading: string;
    remarkDataIsLoading: string;
    shobossoData: any;
    fzeroData: any;
    nobitaV1Data: any;
    remarkData: any;
    customerData: any;
}

const RemarkStatus: React.FC<RemarkStatusProps> = ({
    customerDataIsLoading,
    shobossoDataIsLoading,
    fzeroDataIsLoading,
    nobitaV1DataIsLoading,
    remarkDataIsLoading,
    shobossoData,
    fzeroData,
    nobitaV1Data,
    remarkData,
    customerData
}) => {
    const [remarkStatus, setRemarkStatus] = useState<string>("");
    const [remarkStatusIsLoading, setRemarkStatusIsLoading] = useState<string>("loading");

    useEffect(() => {
        if ([customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading].some(status => status === "loading")) {
            return;
        } else if ([customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading].some(status => status === "error")) {
            setRemarkStatus("Failed to load remark information");
            setRemarkStatusIsLoading("loaded");
            return;
        }
        let tmpRemarkStatusEn = "";
        let tmpRemarkStatusJa = "";
        if (shobossoData?.has_remark) {
            tmpRemarkStatusEn += "HENNGE Access Control ";
            tmpRemarkStatusJa += "HENNGE Access Control ";
        }
        if (fzeroData?.has_remark) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "HENNGE Email DLP";
            tmpRemarkStatusJa += "HENNGE Email DLP";
        }
        if (nobitaV1Data?.has_remark) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "HENNGE Email Archive";
            tmpRemarkStatusJa += "HENNGE Email Archive";
        }
        if (remarkData?.csegdb_one_tenant) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "CSEGDB One Tenant";
            tmpRemarkStatusJa += "CSEGDB One Tenant";
        }
        if (remarkData?.csegdb_account) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "CSEGDB Account";
            tmpRemarkStatusJa += "CSEGDB Account";
        }
        if (remarkData?.sfdc_memo) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "SFDC Memo";
            tmpRemarkStatusJa += "SFDC Memo";
        }
        if (remarkData?.sfdc_irregular_details) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += ", ";
                tmpRemarkStatusJa += "、";
            }
            tmpRemarkStatusEn += "SFDC Irregular Details";
            tmpRemarkStatusJa += "SFDC Irregular Details";
        }
        if (tmpRemarkStatusEn !== "") {
            tmpRemarkStatusEn = "This tenant has remarks for " + tmpRemarkStatusEn + ".\nPlease check the remarks for details.";
            tmpRemarkStatusJa = "このテナントは" + tmpRemarkStatusJa + "に注意事項があります。\nリテンション訪問やサポート時には、必ず注意事項を確認してください。";
        }
        if (customerData?.is_irregular) {
            if (tmpRemarkStatusEn !== "") {
                tmpRemarkStatusEn += "\n\n";
                tmpRemarkStatusJa += "\n\n";
            }
            tmpRemarkStatusEn += "This tenant is an irregular tenant.";
            tmpRemarkStatusJa += "このテナントはイレギュラーテナントです。";
        }
        if (tmpRemarkStatusEn !== "") {
            setRemarkStatus(tmpRemarkStatusEn + "\n\n\n" + tmpRemarkStatusJa);
        }
        setRemarkStatusIsLoading("loaded");
    }, [customerData, shobossoData, fzeroData, nobitaV1Data, remarkData, customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading]);

    return (
        <>
            {
                remarkStatusIsLoading === "loaded" && remarkStatus &&
                <Box shadow="md" bg="red.100" p={4} >
                    <Text style={{ whiteSpace: 'pre-line' }}>
                        {remarkStatus}
                    </Text>
                </Box>
            }
            {remarkStatusIsLoading === "loading" &&
                <Progress size="xs" isIndeterminate colorScheme="teal" />}
        </>
    );
};

export default RemarkStatus;
