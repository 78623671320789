import {
    Link,
    Text,
    Flex,
    Avatar,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Stack,
    Center,
    HStack,
    Heading,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Textarea,
    VStack,
    IconButton,
    chakra,
} from '@chakra-ui/react';

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from './SearchBar';
import { API_ROOT, LOGOUT_ENDPOINT, GOOGLE_FORM_URL } from '../assets/const';
import { FaHeart } from "react-icons/fa"
import { FaPaperPlane } from "react-icons/fa"
import axios from 'axios';
import { RedirectToLogin } from '../assets/utils';
import logo from '../assets/logo.png';

type HeaderProps = {
    user_email: string;
}

export const Header = ({ user_email }: HeaderProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const RedirectToLogout = () => window.location.replace(LOGOUT_ENDPOINT);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [slackPostUrl, setSlackPostUrl] = useState<string>("");
    const [input, setInput] = useState("");
    const handleOnClick = useCallback(() => navigate("/", { replace: false }), [navigate]);
    const isHome = window.location.pathname === "/";

    return (
        <Flex shadow="sm" px={4} h={16} as="header" position="fixed" w="100%" bgColor="white" zIndex={200}>
            <HStack cursor="pointer" onClick={handleOnClick}>
                <chakra.img src={logo} boxSize={10} />
                <Heading size="lg" color="black" >
                    Daifukucho
                </Heading>
            </HStack>
            <Spacer />
            {!isHome ? <SearchBar isHome={false} /> : null}
            <Flex alignItems={'center'}>
                <HStack spacing={4} mr={8}>
                    <IconButton ml={4} aria-label='send feedback' colorScheme="teal" icon={<FaPaperPlane />} onClick={function (_) {
                        onOpen();
                    }} _focus={{ boxShadow: "none" }} />

                    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside" size="md" >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                Send Feedback
                            </ModalHeader>
                            <ModalCloseButton _focus={{ boxShadow: "none" }} />
                            <ModalBody>
                                <Stack>
                                    <Text>Feel free to send your feedback from <Link isExternal color="teal" href={GOOGLE_FORM_URL}>this form</Link>!</Text>
                                </Stack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                    <Menu>
                        <MenuButton
                            _focus={{ boxShadow: "none" }}
                            as={Button}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            minW={0}>
                            <Avatar />
                        </MenuButton>
                        <MenuList alignItems={'center'}>
                            <Center>
                                <p>{user_email}</p>
                            </Center>
                            <MenuDivider />
                            <MenuItem onClick={RedirectToLogout}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Flex>
        </Flex>
    );
}