import React from 'react';
import { Box, Stack, Heading, Text, Progress } from '@chakra-ui/react';
import parse from 'html-react-parser';
import { Remark } from '../assets/types';

interface RemarksSectionProps {
    remarkData: Remark | null;
}

const RemarkItem: React.FC<{ title: string, content: string | null }> = ({ title, content }) => (
    <Stack py={2}>
        <Heading as="h4" size="sm">
            {title}
        </Heading>
        {content ? (
            <Text backgroundColor="red.100" p={4} style={{ whiteSpace: 'pre-line' }}>
                {parse(content)}
            </Text>
        ) : (
            <Text>No data</Text>
        )}
    </Stack>
);

const RemarksSection: React.FC<RemarksSectionProps> = ({ remarkData }) => (
    <Box id="remark" shadow="md" bg="white" p={4}>
        <Stack spacing={4}>
            <Heading as="h2" size="md">
                Remarks
            </Heading>
            {
                remarkData ?
                    <Stack>
                        <RemarkItem title="CSEGDB One Tenant" content={remarkData.csegdb_one_tenant} />
                        <RemarkItem title="CSEGDB Account" content={remarkData.csegdb_account} />
                        <RemarkItem title="SFDC Memo" content={remarkData.sfdc_memo} />
                        <RemarkItem title="SFDC Irregular Details" content={remarkData.sfdc_irregular_details} />
                    </Stack> :
                    <Progress size="xs" isIndeterminate colorScheme="teal" />
            }
        </Stack>
    </Box>
);

export default RemarksSection;
