import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Skeleton
} from '@chakra-ui/react';
import { ServiceBadge } from './ServiceBadge';
import { Customer, NumberOfLicensesType } from '../assets/types';
import { SERVICES } from '../assets/const';

interface ServiceTableProps {
    customerData: Customer | null;
    numberOfLicenses: NumberOfLicensesType | null;
}

const ServiceTable: React.FC<ServiceTableProps> = ({ customerData, numberOfLicenses }) => {
    return (
        <Table variant='simple' backgroundColor="white" shadow="md">
            <Thead>
                <Tr>
                    <Th><Center>Service Name</Center></Th>
                    <Th><Center>Status</Center></Th>
                    <Th><Center>Number of licenses</Center></Th>
                </Tr>
            </Thead>
            <Tbody>
                {customerData ? (
                    SERVICES.map(service => (
                        <Tr key={service.key}>
                            <Td><Center><ServiceBadge status={String(customerData[service.key as keyof Customer])} serviceName={service.name} /></Center></Td>
                            <Td><Center>{customerData[service.key as keyof Customer]}</Center></Td>
                            <Td><Center>{numberOfLicenses ? numberOfLicenses[service.key as keyof NumberOfLicensesType] : <Skeleton width={30} height={6} />}</Center></Td>
                        </Tr>
                    ))
                ) : (
                    [...Array(8)].map((_, index) => (
                        <Tr key={index}>
                            <Td><Center><Skeleton width={30} height={6} /></Center></Td>
                            <Td><Center><Skeleton width={30} height={6} /></Center></Td>
                            <Td><Center><Skeleton width={30} height={6} /></Center></Td>
                        </Tr>
                    ))
                )}
            </Tbody>
        </Table>
    );
};

export default ServiceTable;
