import React from 'react';
import { Stack, HStack, Heading, Skeleton, Button } from '@chakra-ui/react';
import { CopyButton } from './CopyButton';
import { DossunButton } from './DossunButton';
import { Customer } from '../assets/types';

interface CustomerInfoProps {
  customer_id: string;
  customerData: Customer | null;
  handleUsageStatusClick: () => void;
  dossunJobsData: any; // Replace 'any' with the appropriate type if available
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ customer_id, customerData, handleUsageStatusClick, dossunJobsData }) => (
  <Stack fontSize="md" spacing={4}>
    <HStack spacing={4}>
      <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
      {customer_id ? <CopyButton text={customer_id} /> : <></>}
      <Button colorScheme='yellow' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={handleUsageStatusClick}>
        Usage status
      </Button>
      <DossunButton customer_id={customer_id} dossunJobs={dossunJobsData} />
    </HStack>
    <HStack>
      <Heading size="md" color="grey.700">
        {customerData ? customerData.service_id : <Skeleton width={120} height={6} />}
      </Heading>
      {customerData ? <CopyButton text={customerData.service_id} /> : <></>}
    </HStack>
    <HStack>
      <Heading size="md" color="grey.700">
        {customerData ? customerData.company_name_ja : <Skeleton width={150} height={6} />}
      </Heading>
      {customerData ? <CopyButton text={customerData.company_name_ja} /> : <></>}
    </HStack>
    <HStack>
      <Heading size="md" color="grey.700">
        {customerData ? customerData.company_name_en : <Skeleton width={180} height={6} />}
      </Heading>
      {customerData ? <CopyButton text={customerData.company_name_en} /> : <></>}
    </HStack>
  </Stack>
);

export default CustomerInfo;
