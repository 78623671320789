import React from 'react';
import { Box, VStack, HStack, Text, Progress } from '@chakra-ui/react';

interface StatusInformationProps {
    statusInformationLoadingStatus: string;
    statusInformationData: {
        account?: {
            name: string;
            status: string;
            status_message: string;
        };
        end_user_account?: {
            name: string;
            status: string;
            status_message: string;
        };
    } | null;
}

const StatusInformation: React.FC<StatusInformationProps> = ({ statusInformationLoadingStatus, statusInformationData }) => {
    const isLoading = statusInformationLoadingStatus === "loading";
    const isError = statusInformationLoadingStatus === "error";
    const isLoaded = statusInformationLoadingStatus === "loaded";
    const account = statusInformationData?.account;
    const endUserAccount = statusInformationData?.end_user_account;

    return (
        <>
            {isLoading && <Progress size="xs" isIndeterminate colorScheme="teal" />}
            {isError && <Text>Failed to load status information</Text>}
            {isLoaded && account && <Box shadow="md" bg="white" p={4}>
                <VStack spacing={4}>
                    <Text fontSize={14} color="grey">{account.name}</Text>
                    <HStack spacing={8}>
                        <Text fontWeight="bold" fontSize="3xl">
                            {account.status}
                        </Text>
                        <Box>
                            <Text>
                                {account.status_message}
                            </Text>
                        </Box>
                    </HStack>
                </VStack>
            </Box>
            }
            {isLoaded && endUserAccount && <Box shadow="md" bg="white" p={4}>
                <VStack spacing={4}>
                    <Text fontSize={14} color="grey">{endUserAccount.name}</Text>
                    <HStack spacing={8}>
                        <Text fontWeight="bold" fontSize="3xl">
                            {endUserAccount.status}
                        </Text>
                        <Box>
                            <Text>
                                {endUserAccount.status_message}
                            </Text>
                        </Box>
                    </HStack>
                </VStack>
            </Box>
            }
        </>
    );
};

export default StatusInformation;
