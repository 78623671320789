import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SkeletonCircle,
  Center,
  Skeleton,
  HStack,

} from "@chakra-ui/react"

import { Header } from "./components/Header"
import { SearchBar } from "./components/SearchBar"
import { TenantTableRow } from "./components/TenantTableRow"
import { theme } from "./Theme";
import axios from 'axios';
import { useEffect, useState } from "react";
import { API_ROOT } from './assets/const';
import { Customer } from './assets/types';
import { RedirectToLogin } from "./assets/utils";


export const Home = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [customerData, setCustomerData] = useState<Customer[] | null>(null);

  useEffect(() => {
    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)
        axios.get<Customer[]>(`${API_ROOT}/recently_connected_customers`)
          .then((response) => {
            setCustomerData(response.data);
          }).catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      })
  }, [])

  if (loading) {
    return <p>loading...</p>
  }
  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Box fontSize="lg" mx={50} as="main" pt="20">
        <VStack spacing={12} mt={10} >
          <Box my={4}>
            <SearchBar isHome={true} />
          </Box>
          <VStack align="left">
            <Heading as="h4" size="md" pb={2}>
              Recently new connected tenants
            </Heading>
            <Box bg="white" shadow="md">
              <Table variant='simple'>
                <Thead >
                  <Tr>
                    <Th>ID</Th>
                    <Th>Tenant</Th>
                    <Th>Company</Th>
                    <Th>Domains</Th>
                    <Th>Connection Date</Th>
                    <Th>Platform</Th>
                    <Th>Status</Th>
                    <Th>Services</Th>
                  </Tr>
                </Thead>

                {customerData ? <Tbody>
                  {Object.values(customerData).map((data: Customer, index: number) =>
                    <TenantTableRow key={index}
                      service_id={data.service_id}
                      customer_id={data.customer_id}
                      company_name={data.company_name_ja}
                      domains={data.domains}
                      date={data.connection_date}
                      service_type={data.service_type}
                      status={data.env}
                      fzero={data.fzero}
                      archive={data.archive}
                      hos={data.hos}
                      hac={data.hac}
                      hcp={data.hcp}
                      connect={data.connect}
                      saascore={data.saascore}
                      tadrill={data.tadrill}
                    />
                  )}
                </Tbody> :
                  <Tbody>
                    {[...Array(5)].map((_, index: number) => <Tr key={index}>
                      <Td><Skeleton width={20} height={4} /></Td>
                      <Td><Skeleton width={40} height={4} /></Td>
                      <Td><Skeleton width={60} height={4} /></Td>
                      <Td><Skeleton width={30} height={4} /></Td>
                      <Td><Center><Skeleton width={20} height={4} /></Center></Td>
                      <Td><Center><SkeletonCircle /></Center></Td>
                      <Td><Center><Skeleton width={20} height={4} /></Center></Td>
                      <Td><VStack><HStack>{[...Array(4)].map((_, index: number) => <Skeleton key={index} width={8} height={5} />)}</HStack>
                        <HStack>{[...Array(4)].map((_, index: number) => <Skeleton key={index} width={8} height={5} />)}</HStack></VStack></Td>
                    </Tr>)}
                  </Tbody>}
              </Table>
            </Box>
          </VStack>
        </VStack>
      </Box>
    </ChakraProvider>
  )
}
