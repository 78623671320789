import {
  ChakraProvider,
  Stack,
} from "@chakra-ui/react"

import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import { TenantDetailCard } from './components/TenantDetailCard';
import { theme } from "./Theme";
import axios from 'axios';
import { useCallback, useEffect, useState } from "react"
import { API_ROOT } from './assets/const';
import { Customer, Services, DomainInfo, Remark, NumberOfLicensesType, StatusInformationResponse, DossunJobs } from './assets/types';
import { RedirectToLogin } from "./assets/utils";
import { NavigationMenu } from "./components/NavigationMenu";
import CustomerInfo from "./components/CustomerInfo";
import CustomerDetailsGrid from "./components/CustomerDetailsGrid";
import StatusInformation from "./components/StatusInformation";
import ServiceTable from "./components/ServiceTable";
import DomainAccordion from "./components/DomainAccordion";
import ChartSection from "./components/ChartSection";
import RemarksSection from "./components/RemarksSection";
import RemarkStatus from "./components/RemarkStatus";

export const TenantDetail = () => {
  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id") ?? "";
  const [email, setEmail] = useState<string>("");

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [customerDataIsLoading, setCustomerDataIsLoading] = useState<string>("loading");
  const [dossunJobsData, setDossunJobsData] = useState<DossunJobs | null>(null);
  const [statusInformationData, setStatusInformationData] = useState<StatusInformationResponse | null>(null);
  const [statusInformationLoadingStatus, setStatusInformationLoadingStatus] = useState<string>("loading");
  const [customerDomains, setCustomerDomains] = useState<string[] | null>(null);
  const [customerDomainsIsLoading, setCustomerDomainsIsLoading] = useState<string>("loading");
  const [shobossoData, setShobossoData] = useState<Services | null>(null);
  const [shobossoDataIsLoading, setShobossoDataIsLoading] = useState<string>("loading");
  const [fzeroData, setFzeroData] = useState<Services | null>(null);
  const [fzeroDataIsLoading, setFzeroDataIsLoading] = useState<string>("loading");
  const [nobitaV1Data, setNobitaV1Data] = useState<Services | null>(null);
  const [nobitaV1DataIsLoading, setNobitaV1DataIsLoading] = useState<string>("loading");
  const [kumamushiV1Data, setKumamushiV1Data] = useState<Services | null>(null);
  const [kumamushiV1DataIsLoading, setKumamushiV1DataIsLoading] = useState<string>("loading");
  const [kumamushiV2Data, setKumamushiV2Data] = useState<Services | null>(null);
  const [kumamushiV2DataIsLoading, setKumamushiV2DataIsLoading] = useState<string>("loading");
  const [saascoreData, setSaascoreData] = useState<Services | null>(null);
  const [saascoreDataIsLoading, setSaascoreDataIsLoading] = useState<string>("loading");
  const [tadrillData, setTadrillData] = useState<Services | null>(null);
  const [tadrillDataIsLoading, setTadrillDataIsLoading] = useState<string>("loading");
  const [numberOfLicenses, setNumberOfLicenses] = useState<NumberOfLicensesType | null>(null);
  const [domainData, setDomainData] = useState<DomainInfo | null>(null);
  const [remarkData, setRemarkData] = useState<Remark | null>(null);
  const [remarkDataIsLoading, setRemarkDataIsLoading] = useState<string>("loading");
  const [loading, setLoading] = useState<boolean>(true);
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const handleUsageStatusClick = useCallback(() => {
    openInNewTab('/usage_status?customer_id=' + customer_id)
  }, [customer_id]);

  useEffect(() => {
    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)

        axios.get(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
            setCustomerDataIsLoading("loaded");
          }).catch(error => {
            console.log(error);
            setCustomerDataIsLoading("error");
          });

        axios.get(`${API_ROOT}/status_information/${customer_id}`)
          .then((response) => {
            setStatusInformationData(response.data);
            setStatusInformationLoadingStatus("loaded");
          }).catch(error => {
            console.log(error);
            setStatusInformationLoadingStatus("error");
          });

        axios.get(`${API_ROOT}/number_of_licenses/${customer_id}`)
          .then((response) => {
            setNumberOfLicenses(response.data);
          }).catch(error => {
            console.log(error);
          });

        axios.get(`${API_ROOT}/domain/${customer_id}`)
          .then((response) => {
            setDomainData(response.data);
          }).catch(error => {
            console.log(error);
          });


        axios.get(`${API_ROOT}/customer_domains/${customer_id}`)
          .then((response) => {
            setCustomerDomains(response.data);
            setCustomerDomainsIsLoading("loaded")
          }).catch(error => {
            console.log(error);
            setCustomerDomainsIsLoading("error")
          });
        const serviceEndpoints = [
          { url: `${API_ROOT}/services_shobosso/${customer_id}`, setData: setShobossoData, setLoading: setShobossoDataIsLoading },
          { url: `${API_ROOT}/services_fzero/${customer_id}`, setData: setFzeroData, setLoading: setFzeroDataIsLoading },
          { url: `${API_ROOT}/services_nobita_v1/${customer_id}`, setData: setNobitaV1Data, setLoading: setNobitaV1DataIsLoading },
          { url: `${API_ROOT}/services_kumamushi_v1/${customer_id}`, setData: setKumamushiV1Data, setLoading: setKumamushiV1DataIsLoading },
          { url: `${API_ROOT}/services_kumamushi_v2/${customer_id}`, setData: setKumamushiV2Data, setLoading: setKumamushiV2DataIsLoading },
          { url: `${API_ROOT}/services_saascore/${customer_id}`, setData: setSaascoreData, setLoading: setSaascoreDataIsLoading },
          { url: `${API_ROOT}/services_tadrill/${customer_id}`, setData: setTadrillData, setLoading: setTadrillDataIsLoading },
        ];

        serviceEndpoints.forEach(({ url, setData, setLoading }) => {
          axios.get(url)
            .then((response) => {
              setData(response.data);
              setLoading("loaded");
            }).catch(error => {
              if (error.response && error.response.status === 404) {
                setLoading("loaded");
              } else {
                console.log(error);
                setLoading("error");
              }
            });
        });

        axios.get(`${API_ROOT}/remark/${customer_id}`)
          .then((response) => {
            setRemarkData(response.data);
            setRemarkDataIsLoading("loaded")
          }).catch(error => {
            console.log(error);
            setRemarkDataIsLoading("error")
          });

      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });
  }, [customer_id]);

  useEffect(() => {
    axios.get(`${API_ROOT}/dossun_jobs`)
      .then((response) => {
        setDossunJobsData(response.data.jobs);
      }).catch(error => {
        console.log(error);
      });
  }, []);

  if (loading) {
    return <>Loading...</>
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <NavigationMenu />
      <Stack id="title" fontSize="md" px={40} py={8} spacing={10} as="main" pt="20">
        <CustomerInfo
          customer_id={customer_id}
          customerData={customerData}
          handleUsageStatusClick={handleUsageStatusClick}
          dossunJobsData={dossunJobsData}
        />
        <CustomerDetailsGrid customerData={customerData} domainData={domainData} />
        <StatusInformation
          statusInformationLoadingStatus={statusInformationLoadingStatus}
          statusInformationData={statusInformationData}
        />
        <RemarkStatus
          customerDataIsLoading={customerDataIsLoading}
          shobossoDataIsLoading={shobossoDataIsLoading}
          fzeroDataIsLoading={fzeroDataIsLoading}
          nobitaV1DataIsLoading={nobitaV1DataIsLoading}
          remarkDataIsLoading={remarkDataIsLoading}
          shobossoData={shobossoData}
          fzeroData={fzeroData}
          nobitaV1Data={nobitaV1Data}
          remarkData={remarkData}
          customerData={customerData}
        />
        <ServiceTable customerData={customerData} numberOfLicenses={numberOfLicenses} />
        <DomainAccordion
          domainData={domainData}
          customerDomains={customerDomains}
          shobossoData={shobossoData}
          fzeroData={fzeroData}
          nobitaV1Data={nobitaV1Data}
          kumamushiV1Data={kumamushiV1Data}
          kumamushiV2Data={kumamushiV2Data}
          customerDomainsIsLoading={customerDomainsIsLoading}
          shobossoDataIsLoading={shobossoDataIsLoading}
          fzeroDataIsLoading={fzeroDataIsLoading}
          nobitaV1DataIsLoading={nobitaV1DataIsLoading}
          kumamushiV1DataIsLoading={kumamushiV1DataIsLoading}
          kumamushiV2DataIsLoading={kumamushiV2DataIsLoading}
        />
        <ChartSection customer_id={customer_id} />
        <TenantDetailCard title="HENNGE Access Control" serviceName="shobosso" servicesData={shobossoData} loadingStatus={shobossoDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="HENNGE Email DLP" serviceName="fzero" servicesData={fzeroData} loadingStatus={fzeroDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="HENNGE Email Archive" serviceName="nobita_v1" servicesData={nobitaV1Data} loadingStatus={nobitaV1DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="HENNGE Secure Transfer v1" serviceName="kumamushi_v1" servicesData={kumamushiV1Data} loadingStatus={kumamushiV1DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="HENNGE Secure Transfer v2" serviceName="kumamushi_v2" servicesData={kumamushiV2Data} loadingStatus={kumamushiV2DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="File DLP" serviceName="saascore" servicesData={saascoreData} loadingStatus={saascoreDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <TenantDetailCard title="Tadrill" serviceName="tadrill" servicesData={tadrillData} loadingStatus={tadrillDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
        <RemarksSection remarkData={remarkData} />
      </Stack>
    </ChakraProvider>
  )
}
